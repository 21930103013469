import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaCheck } from 'react-icons/fa';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { IoArrowForward } from "react-icons/io5";
import './Footer.css';

const Footer = () => {
    return (
        <Container fluid>
            <footer className="footer">
                <div className="footer-container">
                    <div className="row mt-5">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-md-0">
                            <div className="circular-bg py-2">
                                <h5>Want to create something <br />
                                    together?
                                </h5>
                                <Link to='/contact' className='mt-3 getintouch'>GET IN TOUCH</Link>
                                <br />
                                <h5>Help us to make more cool things!</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4 mb-md-0">
                            <div className="pt-5">
                                <p> India</p>
                                <h5>278X+G93, Jaale Siyaad,<br />
                                    India</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 mb-4 mb-md-0">
                            <div>
                                <h5>Keep us with news form us</h5>
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="email" style={{ position: "relative" }}>
                                            <FaEnvelope style={{ position: "absolute", right: "0px", top: "40%", transform: "translateY(-50%)" }} />
                                            <input type="email" className="form-control footeremail" id="email" placeholder="info@we" />
                                        </label>
                                    </div>

                                    <div className="form-group form-check">
                                        <input type="checkbox" className="form-check-input" id="terms" />
                                        <label className="form-check-label" htmlFor="terms"><FaCheck /> I agree to the terms and conditions</label>
                                    </div>
                                    <button type="submit" className="btn btn-custom">Submit <IoArrowForward style={{ marginTop: "-3px" }} /></button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second Navbar */}
                <Container>
                    <nav className="navbar navbar-expand-lg footer-navbar p-5">
                        <div className="container">
                            <Link className="navbar-brand col-lg-4 col-md-12 col-sm-12" to="/">
                                <h5>HK Smart Store</h5>
                            </Link>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#footerNavbar" aria-controls="footerNavbar" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse col-lg-8 col-md-12 col-sm-12" id="footerNavbar">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/">Home</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/categories">Categories</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/AboutUs">About Us</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/contact">Contact</Link>
                                    </li>
                                    <li className="nav-item social-icons">
                                        <a className="nav-link" target="_blank"  href="https://www.facebook.com"><FaFacebookF /></a>
                                    </li>
                                    <li className="nav-item social-icons">
                                        <a className="nav-link" target="_blank" href="https://www.twitter.com"><FaTwitter /></a>
                                    </li>
                                    <li className="nav-item social-icons">
                                        <a className="nav-link" target="_blank" href="https://www.instagram.com"><FaInstagram /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                </Container>
            </footer>
        </Container>
    );
};

export default Footer;
