import React, { useEffect, useState } from "react";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { CiLocationOn } from "react-icons/ci";
import axios from "axios";
import "./Css/Listing.css";
import image1 from "./Images/bg1.png";

const Listing = () => {
    const [featuredProducts, setFeaturedProducts] = useState([]);
    const [latestProducts, setLatestProducts] = useState([]);

    useEffect(() => {
        const fetchFeaturedProducts = async () => {
            try {
                const response = await axios.get("https://hksmartstore.adef.tech/FeaturedProduct");
                setFeaturedProducts(response.data.slice(0, 4)); // Assuming you want to display the first 4 items
            } catch (error) {
                console.error("Error fetching featured products:", error);
            }
        };

        const fetchLatestProducts = async () => {
            try {
                const response = await axios.get("https://hksmartstore.adef.tech/LatestProduct");
                setLatestProducts(response.data.slice(0, 4)); // Assuming you want to display the first 4 items
            } catch (error) {
                console.error("Error fetching latest products:", error);
            }
        };

        fetchFeaturedProducts();
        fetchLatestProducts();
    }, []);

    return (
        <Container className="mt-5">
            <Row>
                <Col md={6}>
                    <div>
                        <p className="title_small mb-0">Hand-Picked</p>
                        <h3 className="mb-4 getevery">Hand-picked <span className="category">listings</span></h3>
                    </div>
                    <Carousel fade prevIcon={<FaArrowLeft />} nextIcon={<FaArrowRight />} interval={null}>
                        {featuredProducts.map((product, index) => (
                            <Carousel.Item key={index}>
                                <img src={product.display_image} className="curoselimage" alt={product.name} />
                                <Carousel.Caption>
                                    <p className="location"><CiLocationOn /> <span>{product.area}</span></p>
                                    <p>{product.name}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    {featuredProducts.map((product, index) => (
                        <div className="d-flex mt-3" key={index}>
                            <div>
                                <img src={product.display_image || image1} className="image1" alt={product.name} />
                            </div>
                            <div>
                                <div className='location'>
                                    <p><CiLocationOn /> <span>{product.area}</span></p>
                                </div>
                                <h6>{product.name}</h6>
                                <p className="location">{product.price}</p>
                            </div>
                        </div>
                    ))}
                </Col>
                <Col md={6}>
                    <div>
                        <p className="title_small mb-0">Recently added</p>
                        <h3 className="mb-4 getevery">Recently added <span className="category">listings</span></h3>
                    </div>
                    <Carousel fade prevIcon={<FaArrowLeft />} nextIcon={<FaArrowRight />} interval={null}>
                        {latestProducts.map((product, index) => (
                            <Carousel.Item key={index}>
                                <img src={product.display_image} className="curoselimage" alt={product.name} />
                                <Carousel.Caption>
                                    <p className="location"><CiLocationOn /> <span>{product.area}</span></p>
                                    <p>{product.name}</p>
                                </Carousel.Caption>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    {latestProducts.map((product, index) => (
                        <div className="d-flex mt-3" key={index}>
                            <div>
                                <img src={product.display_image || image1} className="image1" alt={product.name} />
                            </div>
                            <div>
                                <div className='location'>
                                    <p><CiLocationOn /> <span>{product.area}</span></p>
                                </div>
                                <h6>{product.name}</h6>
                                <p className="location">{product.price}</p>
                            </div>
                        </div>
                    ))}
                </Col>
            </Row>
        </Container>
    );
};
export default Listing;
