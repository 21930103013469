import { React, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Profile from "./Profile";
import ProfileSideNav from "./ProfileSideNav";
import './Css/Myorders.css';
import image from "./Images/bg.png";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import { MdRemoveRedEye } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
function MyOrders() {
    debugger;
    const [orders, setOrders] = useState([]);
    console.log(orders);
    debugger;
    useEffect(() => {
        fetchOrders();
    }, []);
    const fetchOrders = async () => {
        debugger;
        try {
            const authorId = localStorage.getItem("author_id");
            const userType = 4;
            const url = `https://hksmartstore.adef.tech/List_Bill?authorid=${authorId}&usertype=${userType}`;
            debugger;
            const response = await axios.get(url);
            const responseData = response.data;
            debugger;
            if (responseData != null) {
                setOrders(responseData);
            } else {
                toast.error(responseData.responsemessage || "Error fetching orders");
            }
        } catch (error) {
            toast.error("An error occurred:", error.message);
            console.error("Error:", error);
        }
    };
    const formatDate = (dateString) => {
        const createdDate = new Date(dateString);
        const formattedDate = `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`;
        return formattedDate;
    };

    const handleViewDetails = (orderId) => {
        // Define your logic to handle view details here
        console.log("View details for order ID:", orderId);
    };
    return (
        <>
            <Container className="mt-5 mb-5">
                <Row>
                    <Col md={4}>
                        <ProfileSideNav />
                    </Col>
                    <Col md={8} className="rightsidedata mt-5">
                        <p className="text-center mainhead">My Orders</p>
                        <p className="text-center mainsubhead">Track your open orders & view the summary of your past orders</p>
                        <div className="text-end">
                            <select className="selectdate">
                                <option value="">Select Date</option>
                                <option value="1">1 Month</option>
                                <option value="6">6 Months</option>
                                <option value="12">12 Months</option>
                            </select>
                        </div>
                        <div className="orderslist mt-4">
                            <table className="table ">
                                <thead>
                                    <tr className="text-center">
                                        <th>Order Id</th>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Date</th>
                                        <th>Amount</th>
                                        <th>Status</th>
                                        <th>View Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {orders.map(order => (
                                        <tr key={order.bill_id} className="text-center">
                                            <td>{order.bill_id}</td>
                                            <td>{order.name}</td>
                                            <td>{order.price}</td>
                                            <td>{formatDate(order.createdon)}</td>
                                            <td>{order.amount}</td>
                                            <td>{order.status}</td>
                                            <td>
                                                <Link className="viewdetails" to={`/myorderdetails?cartid=${order.cart_id}`}>
                                                    <MdRemoveRedEye />
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    )
}
export default MyOrders;