import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./ProductDetails.css";
import { Container, Row, Col, Card, Button, Carousel } from "react-bootstrap";
import axios from "axios";
import { FaRegHeart } from "react-icons/fa";
import Breadcrumbs from "./Breadcrumbs";
import { IoCartOutline } from "react-icons/io5";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


debugger;
function ProductDetails() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const productId = searchParams.get("productId");
  const [product, setProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [subcategories, setSubcategories] = useState([]);
  const [showSubcategories, setShowSubcategories] = useState(6);
  const author_id = localStorage.getItem("author_id");
  const cart_id = localStorage.getItem("cart_id");
  debugger;
  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(
          `https://hksmartstore.adef.tech/Productview_Home?id=${productId}`
        );
        if (response.data.responseCode == 0) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data;
        setProduct(data);
      } catch (error) {
        console.error("Error fetching product details:", error);
        setProduct(null);
      }
    };

    fetchProductDetails();
  }, [productId]);

  useEffect(() => {
    fetch("https://hksmartstore.adef.tech/List_Category")
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error("Error fetching categories:", error));
  }, []);

  useEffect(() => {
    fetch("https://hksmartstore.adef.tech/SubcategoryList")
      .then((response) => response.json())
      .then((data) => setSubcategories(data))
      .catch((error) =>
        console.error("Error fetching subcategories:", error)
      );
  }, []);

  const fetchProductsBySubcategoryId = async (subcategoryId) => {
    try {
      const response = await fetch(`https://hksmartstore.adef.tech/GetProductsBySubID?subcategoryId=${subcategoryId}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setProduct(data); // Corrected from setProducts to setProduct
    } catch (error) {
      console.error("Error fetching products by subcategory:", error);
      setProduct([]); // Corrected from setProducts to setProduct
    }
  };
  






  
  const addToCart = async () => {
    debugger;
    try {
      const response = await axios.post(
        "https://hksmartstore.adef.tech/AddCart",
        {
          product_id: productId,
          quantity: 1,
          usertype: 4,
          authorid: author_id,
          cart_id: cart_id,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      debugger;
      if (response.data.responseCode == 0) {
        localStorage.setItem("cart_id", response.data.cart_id);
        toast.success(response.data.responsemessage);
        window.location.href = "/cart";
      } else {
        toast.error(response.data.responsemessage);
      }
    } catch (error) {
      console.error("Error :", error);
      toast.error("Error");
    }
  };

  //--------buy now function-----------//
  const buyNow = async () => {
    debugger;
    try {
      const response = await axios.post(
        "https://hksmartstore.adef.tech/AddCart",
        {
          product_id: productId,
          quantity: 1,
          usertype: 4,
          authorid: author_id,
          cart_id: cart_id,
        },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      debugger;
      if (response.data.responseCode == 0) {
        localStorage.setItem("cart_id", response.data.cart_id);
        toast.success(response.data.responsemessage);
        window.location.href = "/delivery";
      } else {
        toast.error(response.data.responsemessage);
      }
    } catch (error) {
      console.error("Error :", error);
      toast.error("Error");
    }
  };
  const breadcrumbItems = [
    { text: "Home", link: "/" },
    { text: "Products", link:"/productslist" },
    {text:"Products Details",}
  ];
  
  return (
    <>
      <Container fluid className="p-0">
        <div className="breadcrumbs text-center">
        <Breadcrumbs items={breadcrumbItems} />
        </div>
      </Container>
      <Container fluid className="bg-details">
        <Container>
          {product ? (
            <Row className="pt-5">
              <Col md={9}>
                <div>
                  <Card className="p-4">
                    <div>
                      <h2 className="name">{product.name}</h2>
                      <p className="area">
                        <span>{product.area}</span>
                      </p>
                    </div>
                    <div className="text-end d-flex justify-content-end socialmedialinks mb-4">
                      <p className="pt-2">Share</p>
                      <div className="mx-1">
                        <FacebookShareButton
                          url={`https://hksmartstore.adef.tech/Productview_Home?id=${productId}`}
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>
                      </div>
                      <div className="mx-1">
                        <TwitterShareButton
                          url={`https://hksmartstore.adef.tech/Productview_Home?id=${productId}`}
                        >
                          <TwitterIcon size={32} round />
                        </TwitterShareButton>
                      </div>
                      <div className="mx-1">
                        <LinkedinShareButton
                          url={`https://hksmartstore.adef.tech/Productview_Home?id=${productId}`}
                        >
                          <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                      </div>
                      <div className="mx-1">
                        <WhatsappShareButton
                          url={`https://hksmartstore.adef.tech/Productview_Home?id=${productId}`}
                        >
                          <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                      </div>
                    </div>
                    <Card className="p-4">
                      <Carousel controls={true}>
                        <Carousel.Item>
                          <img
                            className="productimg"
                            src={product.display_image}
                            alt="Main Image"
                          />
                        </Carousel.Item>
                        {product.sample_image1 && (
                          <Carousel.Item>
                            <img
                              className="productimg"
                              src={product.sample_image1}
                              alt="Sample Image 1"
                            />
                          </Carousel.Item>
                        )}
                        {product.sample_image2 && (
                          <Carousel.Item>
                            <img
                              className="productimg"
                              src={product.sample_image2}
                              alt="Sample Image 2"
                            />
                          </Carousel.Item>
                        )}
                        {product.sample_image3 && (
                          <Carousel.Item>
                            <img
                              className="productimg"
                              src={product.sample_image3}
                              alt="Sample Image 3"
                            />
                          </Carousel.Item>
                        )}

                        {product.sample_image4 && (
                          <Carousel.Item>
                            <img
                              className="productimg"
                              src={product.sample_image3}
                              alt="Sample Image 4"
                            />
                          </Carousel.Item>
                        )}

                      </Carousel>
                    </Card>
                    {
                      product.stock != 0 ?
                        <Row>
                          <Col md={6} className="mt-4">
                            <Button className="whishlistbtn" onClick={buyNow}>
                              {" "}
                              Buy Now{" "}
                            </Button>
                          </Col>

                          <Col md={6} className="mt-4">
                            <Button className="cartbtn" onClick={addToCart}>
                              {" "}
                              ADD TO CART <span><IoCartOutline /></span>{" "}
                            </Button>
                          </Col>
                        </Row>
                        :
                        <Col md={12} className="mt-4">

                          <div className="outofstockbtn" disabled>
                            {" "}
                            Out of Stock{" "}
                          </div>
                        </Col>
                    }
                  </Card>
                  <Card className="mt-4 p-4">
                    <h4 className="overview">Overview</h4>
                    <p>{product.description}</p>
                  </Card>
                  <Card className="mt-4 p-4">
                    <h4 className="productvideo">Product Video</h4>
                    <video controls className="productvideo">
                      <source src={product.video} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Card>
                </div>
              </Col>
              <Col md={3}>
                <Card className="p-3">
                  <p>Price</p>
                  <p className="detailsprice">
                    {" "}
                    ${product.price} <span><del>${product.rate}</del></span>{" "}
                  </p>
                </Card>
                <Card className="filter-card text-center mt-5">
              <h5 className="product-categories mt-5">
                Sub Categories
              </h5>
              <Container>
                <div className="container-fluid mt-5">
                  <div className="row">
                    {subcategories.slice(0, showSubcategories).map(subcategory => (
                      <div className="col-12" key={subcategory.id}>
                        <Link
                          to="#"
                          className="card1"
                          onClick={() => fetchProductsBySubcategoryId(subcategory.id)}
                        >
                          <div className="cateory_div">
                            <div className=" d-flex justify-content-between">
                              <div className="d-flex">
                                {subcategory.image && (
                                  <img src={subcategory.image} alt={subcategory.name} className="image-icon" />
                                )}
                                <p className="">{subcategory.name}</p>
                              </div>
                              <div className="">
                                <p>{subcategory.productcount}</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                </div>
                {subcategories.length > showSubcategories && (
                  <div className="text-start mb-3 mt-3">
                    <Button
                      className="button-product"
                      onClick={() => setShowSubcategories(showSubcategories + 6)}
                    >
                      Show More
                    </Button>
                  </div>
                )}
              </Container>
            </Card>

                <Card className="filter-card text-center mt-5">
                  <h5 className="product-categories mt-5">More Categories</h5>
                  <Container>
                    <div className="container-fluid mt-5">
                      <div className="row">
                        {categories
                          .slice(
                            0,
                            showAllCategories
                              ? categories.length
                              : 6
                          )
                          .map((category) => (
                            <div className="col-12" key={category.id}>
                              <Link
                                to={`/productslist?cat=${category.name}`}
                                className="card1"
                              >
                                <div className="cateory_div">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                      {category.image && (
                                        <img
                                          src={category.image}
                                          alt={category.name}
                                          className="image-icon"
                                        />
                                      )}
                                      <p className="">{category.name}</p>
                                    </div>
                                    <div className="">
                                      <p>{category.productcount}</p>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))}
                      </div>
                      {!showAllCategories &&
                        categories.length > 6 && (
                          <div className="text-start mb-3 mt-3">
                            <Button
                              className="button-product"
                              onClick={() =>
                                setShowAllCategories(true)
                              }
                            >
                              Show More
                            </Button>
                          </div>
                        )}
                    </div>
                  </Container>
                </Card>
              </Col>
            </Row>
          ) : (
            <p>Loading...</p>
          )}
          <ToastContainer />
        </Container>
      </Container>
    </>
  );
}

export default ProductDetails;





















































