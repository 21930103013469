import React, { useState, useEffect } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import ProfileSideNav from './ProfileSideNav';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";
function AddAddress() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get("from");
    const [authorId, setAuthorId] = useState(localStorage.getItem('author_id') || "");
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddressData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const [addressData, setAddressData] = useState({
        name: "",
        address: "",
        city: "",
        phone: "",
        state: "",
        pincode: "",
        area: "",
        email: "",
        usertype: 4,
        authorId: authorId,
    });
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const requiredFields = ['name', 'phone', 'city', 'state', 'pincode', 'area', 'email'];
            const missingFields = requiredFields.filter(field => !addressData[field]);
            if (missingFields.length > 0) {
                toast.error(`Please fill out all required fields: ${missingFields.join(', ')}`);
                return;
            }

            const url = "https://hksmartstore.adef.tech/Create_Address";
            const formData = new URLSearchParams(addressData);

            const response = await fetch(url, {
                method: "POST",
                body: formData,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });

            const responseData = await response.json();

            if (responseData.responseCode === 0) {
                toast.success(responseData.responsemessage, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setTimeout(() => {
                    if(from){
                        window.location.href = "/address?from=delivery";
                    }
                    else{
                    window.location.href = "/address";
                }
                }, 4000);
            } else {
                toast.error(
                    responseData.responsemessage || "Invalid credentials.",
                    {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
        } catch (error) {
            toast.error("An error occurred:", error.message);
            console.error("Error:", error);
        }
    };


    return (
        <Container className='mt-5'>
            <Row>
                <Col md={4}>
                    <ProfileSideNav />
                </Col>
                <Col md={8} className='rightsidedata mt-5'>
                    <Form className="form-container mt-3" onSubmit={handleSubmit}>
                        <Row>

                            <Col md={6}>
                                <Form.Group controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        name="name"
                                        value={addressData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        placeholder="Enter Email"
                                        name="email"
                                        value={addressData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formPhone">
                                    <Form.Label>Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Phone"
                                        name="phone"
                                        value={addressData.phone}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter City"
                                        name="city"
                                        value={addressData.city}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formArea">
                                    <Form.Label>Area</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Area"
                                        name="area"
                                        value={addressData.area}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formState">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter State"
                                        name="state"
                                        value={addressData.state}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formPincode">
                                    <Form.Label>Pincode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Pincode"
                                        name="pincode"
                                        value={addressData.pincode}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                <Form.Group controlId="formAddress">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        type="text"
                                        placeholder="Enter Address"
                                        name="address"
                                        value={addressData.address}
                                        onChange={handleChange}
                                        required
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Button variant="success" className="btn btn-form-control" type="submit">Save</Button>
                    </Form>
                </Col>

            </Row>
            <ToastContainer />
        </Container>
    )
}
export default AddAddress;